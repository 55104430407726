.brand-promo {
    padding-bottom: $spacer--extra-large;
    background: $gray-lighter;

    &__wrapper {
        @include mq($screen-m) {
            margin: 0 55px;
        }
        @include mq($screen-l) {
            display: grid;
            grid-template-areas: "image spacer data-wrapper";
            grid-template-columns: minmax(387px, 656px) 95px minmax(464px, 656px);
            justify-content: center;
        }
    }
    &__image {
        @include mq($screen-m) {
            grid-area: image;
        }
        @include mq($screen-l) {
            padding: 0;
            width: auto;
            height: 720px;
        }
        @include mq($screen-xxl) {
            height: auto;
        }
        .lazyload-wrapper {
            background: $gray-lighter;
            @include mq($screen-m) {
                position: relative;
                height: 100%;
            }
        }
        .image {
            margin: 0 auto;
            @include mq($screen-m) {
                width: 100%;
                max-width: 656px;
            }
            @include mq($screen-l) {
                position: absolute;
                height: 100%;
                width: auto;
                max-width: none;
                right: 0;
            }
        }
    }
    &__spacer {
        display: none;
        @include mq($screen-l) {
            grid-area: spacer;
        }
    }
    &__data-wrapper {
        grid-area: data-wrapper;
        width: 100%;
        max-width: 466px;
        padding: 0 $spacer--small;
        margin: 0 auto;
        @include mq($screen-m) {
            display: grid;
            grid-template-areas: "brand brand" "collection description" "slider slider" "cta cta";
            grid-template-columns: 50% 50%;
        }
        @include mq($screen-l) {
            max-width: 100%;
            grid-template-rows: 32px auto 1fr 48px;
            padding: 0 0 $spacer--medium 0;
            margin-bottom: $spacer--medium;
        }
        @include mq($screen-xxl) {
            grid-template-rows: 32px auto 1fr 48px;
        }
    }
    &__brand-name {
        padding-top: $spacer--extra-large;
        font-size: $font-size-small;
        line-height: 2;
        color: $gray-dark;
        text-transform: uppercase;
        @include mq($screen-m) {
            grid-area: brand;
            font-size: $font-size-base;
        }
        @include mq($screen-l) {
            padding: 0;
        }
    }
    &__brand-link {
        color: $gray-dark;
        &:hover,
        &.focus-visible {
            color: $gray-dark;
        }
    }
    &__collection-name {
        padding-top: $spacer--extra-large;
        font-size: $font-size-large;
        line-height: 2;
        text-transform: uppercase;
        @include mq($screen-m) {
            grid-area: collection;
            font-size: $font-size-extra-large;
        }
        @include mq($screen-l) {
            padding: $spacer--semi-medium 0 0 0;
        }
        @include mq($screen-xxl) {
            font-size: $font-size-super-extra-large;
        }
    }
    &__description {
        padding-top: $spacer--semi-medium;
        line-height: 2;
        @include mq($screen-m) {
            grid-area: description;
            font-size: $font-size-base;
        }
        @include mq($screen-l) {
            min-height: 148px;
            max-height: 348px;
            overflow-y: auto;
            padding: $spacer--semi-medium 0 0 0;
            margin-bottom: $spacer--semi-medium;
        }
        @include mq($screen-xxl) {
            min-height: 236px;
            max-height: 436px;
            font-size: $font-size-medium;
        }
    }
    &__slider {
        padding-top: calc(2 * #{$spacer--extra-large});
        @include mq($screen-m) {
            grid-area: slider;
        }
        @include mq($screen-l) {
            padding: 0;
        }
        .slider__prev,
        .slider__next {
            display: none !important; // sass-lint:disable-line no-important
        }
        .product-grid-item {
            @include mq($screen-l) {
                display: grid !important; // sass-lint:disable-line no-important
            }
        }
        .product-grid-item__link {
            @include mq($screen-l) {
                align-self: start;
            }
        }
    }
    &__cta {
        display: block;
        position: relative;
        margin-top: $spacer--small;
        font-weight: $font-weight-medium;
        line-height: 2;
        color: $gray-darkest;
        letter-spacing: 2.15px;
        text-transform: uppercase;
        text-align: center;
        @include mq($screen-m) {
            grid-area: cta;
            font-size: $font-size-base;
        }
        @include mq($screen-l) {
            align-self: end;
            margin: 0;
        }
    }
    &__arrow {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: $gray-lighter;
        @include mq($screen-m) {
            display: block;
        }
        &:hover,
        &.focus-visible {
            background-color: $gray-lighter;
        }
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
    }
    &--reverse {
        .brand-promo__wrapper {
            @include mq($screen-l) {
                grid-template-areas: "data-wrapper spacer image";
                grid-template-columns: minmax(464px, 656px) 95px  minmax(387px, 656px);
            }
        }
        .brand-promo__image {
            .image {
                @include mq($screen-l) {
                    left: 0;
                }
            }
        }
    }
}
