$banner__content-border             : none;
$banner__text-font-weight           : $font-weight-normal;
$banner__content-padding            : $spacer--extra-large 0 $spacer--semi-medium;
$banner__content-padding\@medium    : $spacer--extra-large 0 $spacer--semi-medium;
$banner__content-after-background   : $gray;
$banner__content-after-left         : 50%;
$banner__content-after-height       : 1px;
$banner__content-after-width-hover  : 88px;

@import 'banner';
@import 'nobleplace-banner';
