@import 'grid';

$cart-padding                : 20px;
$cart-padding-bottom         : 60px;
$cart-title-line-height      : 42px;
$cart-title-font-size        : 36px;
$cart-grid-padding-horizontal: 108px;

.cart-grid {
    margin-top: $spacer--medium;
    margin-left: -$spacer;
    margin-right: -$spacer;
    background: $white;
    padding: 0 $cart-padding;
    border-bottom: 1px solid $gray;

    @include mq($screen-m) {
        &__header {
            display: none;
        }
    }

    @include mq($screen-l) {
        padding: $spacer--large $cart-grid-padding-horizontal $cart-padding-bottom; 
    }

    &__title {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        text-transform: none;
        line-height: $cart-title-line-height;

        @include mq($screen-l) {
            font-size: $cart-title-font-size;
        }
    }

    &__brand {
        font-size: $spacer--small;
        color: $gray-darkest;
    }

    &__name {
        font-size: $spacer--small;
    }
}
