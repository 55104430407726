$tab__title-background              : transparent;
$tab__title-background--active      : transparent;
$tab__title-font-weight             : normal;
$tab__title-font-weight--active     : normal;
$tab__title-font-size               : $font-size-medium;

// todo: remove before merge into develop
$spacer--l                          : 64px;
$tab__padding                       : $spacer--extra-large;
$tab__content-padding\@medium       : $spacer--l;
$tab__content-padding\@large        : $spacer--l;
$tab__content-padding\@extra-large  : $spacer--l;


@import 'tab';
@import 'nobleplace-tab';
