.messages {
    &--pdp {
        .messages__wrap {
            padding-top: $spacer;
            background-color: transparent;
        }

        .message {
            margin-top: 0;
        }
    }
}
