.footer {
    &--checkout {
        .footer__link {
            color: $gray-lighter;

            &:hover {
                color: $gray;
            }
        }
    }

    &__logo {
        margin: auto;
        justify-content: center;
        padding: $spacer--large 0 $spacer--extra-large;
        flex-basis: auto;

        @include mq($screen-m) {
            padding: $spacer--extra-large 0 $spacer--l;
        }

        .logo__image {
            height: 46px;

            @include mq($screen-m) {
                height: 56px;
            }
        }
    }

    &__handler {
        border-bottom: 1px solid $gray;
    }

    &__links {
        border-top: 1px solid $gray-light;
        padding: $spacer--extra-large 0 $spacer--medium;
    }

    &__links-list {
        flex-direction: column;

        .list__column-item {
            padding: 0;

            .footer__link {
                padding: $spacer--small 0;
            }
        }
    }

    &__trustmate-widget {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__bottom-bar-handler {
        padding: $spacer--semi-medium 0;

        @include mq($screen-m) {
            padding: $footer__bottom-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $footer__bottom-padding\@xl;
        }
    }

    &__bottom-bar-links {
        display: flex;
        margin-bottom: $spacer--semi-medium;

        @include mq($screen-m) {
            order: 2;
            margin-bottom: 0;
        }
    }

    &__bottom-bar-link {
        padding: $spacer--medium $spacer--small;
        font-size: $font-size-base;

        @include mq($screen-s) {
            padding: $spacer--medium $spacer--semi-medium $spacer--medium;

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .dropdown-list__label {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $spacer--small 0 $spacer--small $spacer--semi-medium;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        text-align: center;

        @include mq($screen-m) {
            display: block;
            padding: $spacer--small 0;
            margin-bottom: $spacer--medium;
        }
    }

    .dropdown-list__icon {
        position: static;
        margin: 0;
        margin-left: $spacer--medium;
    }

    .dropdown-list__item {
        text-align: center;

        &--collapse {
            margin-bottom: $spacer--semi-medium;

            &:last-child {
                margin-bottom: 0;
            }

            @include mq($screen-m) {
                margin-bottom: 0;
            }
        }
    }
}
