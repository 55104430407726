.catalog-list {
    &__title {
        margin-top: 88px;
    }

    &__products {
        @include mq($screen-l) {
            padding-left: 60px;
        }
    }
}
