$filter__label-text-transform--checkbox: none;
$filter__label-font-size--checkbox     : $font-size-base;

$filter__heading-font-size             : $font-size-base;
$filter__heading-text-transform        : uppercase;

$filter__content-overflow-y            : unset;
$filter__content-max-height--checkbox  : unset;

@import 'filter';
@import 'nobleplace-filter';
