.product-view {
    &__brand {
        display: block;
        text-transform: uppercase;
        @include font-padding($font-size-base, 48px);
    }

    &__title {
        letter-spacing: normal;
        line-height: 1.33;
    }

    &__select-option {
        margin-bottom: $spacer--semi-medium;
    }

    &__buttons {
        display: block;

        .button {
            width: 100%;
            margin-bottom: $spacer--medium;

            &--secondary {
                margin-bottom: 0;
            }
        }
    }

    &__add-to-cart {
        margin-bottom: $spacer--semi-medium;

        &--hidden {
            display: none;
        }
    }

    &__request-presentation {
        &--hidden {
            display: none;
        }
    }

    &__request-product-details {
        &--no-transition {
            transition: none;
        }
    }

    &__details {
        .button--link {
            display: block;
            text-align: center;
            width: 100%;

            @include font-padding($font-size-base, 48px);
        }

        .tooltip {
            @include mq($screen-m) {
                display: inline;
            }

            &__trigger-button {
                display: inline;
                text-align: left;
                padding: 0;
                width: auto;
            }

            &--is-open {
                &:after {
                    @include mq($screen-m) {
                        left: 25px;
                    }
                }
            }
        }

        .configurable-variation-qty {
            display: none;
        }
    }

    &__manufacturer-info {
        margin: $spacer--large 0;
        text-align: center;

        @include mq($screen-m) {
            margin-bottom: 0;
            text-align: left;
        }
    }

    &__gallery-wishlist {
        position: absolute;
        top: 0;
        right: 0;
        z-index: $z-index-normal;

        &:hover,
        &.focus-visible {
            background-color: transparent;
        }
    }

    &__button-wishlist {
        background-color: transparent;
    }

    &__addtowishlist {
        display: flex;
    }

    &__related-products {
        .section__heading {
            margin-top: 0;
            padding-top: 88px;
        }

        .slider__dots {
            position: relative;
        }
    }

    &__description,
    &__price {
        text-align: center;

        @include mq($screen-m) {
            text-align: initial;
        }
    }

    &__indicative-price-note {
        text-transform: uppercase;
        margin-bottom: $spacer--medium;
        font-weight: $font-weight-bold;
    }

    &__stock-info {
        display: block;
        color: #097309;
        margin-bottom: $spacer--small;
        font-weight: 600;
    }

    .fotorama__zoom-in-icon {
        display: none;
    }
}
