.heading {
    &--first-level {
        line-height: $line-height--large;

        @include mq($screen-s) {
            letter-spacing: $letter-spacing--small;
        }

        @include mq($screen-m) {
            letter-spacing: $letter-spacing--medium;
        }

        @include mq($screen-l) {
            letter-spacing: $letter-spacing--large;
        }

        @include mq($screen-xl) {
            font-size: $font-size-super-extra-large;
        }
    }

    &--second-level {
        line-height: $line-height--extra-large;
    }
}
