$cart-list-item__image-width               : 88px;
$cart-list-item__image-width\@medium       : 144px;
$cart-list-item__image-width\@large        : 240px;
$cart-list-item__name-font-size            : 12px;
$cart-list-item__left-margin               : 0;
$cart-list-item__right-margin              : 0 0 0 $spacer;
$cart-list-item__right-padding             : $spacer 0;
$cart-list-item__content-padding           : 0;
$cart-list-item__label-line-height         : unset;
$cart-list-item__input-margin              : 0;
$cart-list-item__qty-flex-direction\@medium: row;
$cart-list-item__right-padding\@medium     : $spacer--medium 0; 

@import 'cart-list-item';
@import 'nobleplace-cart-list-item';
