.section {
    &__heading {
        margin-top: 88px;
        padding-bottom: $spacer--semi-large;

        @include divider-after();
    }

    &__content {
        margin-bottom: 88px;
    }

    &--secondary {
        margin-bottom: 0;

        .section__content {
            margin-bottom: 0;
        }
    }
}
