$toolbar__padding             : $spacer--semi-medium 0;
$toolbar__padding\@medium     : $spacer--semi-medium 0;
$toolbar__padding\@large      : 0 0 $spacer--semi-medium;

$toolbar__border-color        : #e8e8e8;

$toolbar__sorter-width        : auto;
$toolbar__sorter-border-width : 0;
$toolbar__sorter-padding      : 0;
$toolbar__sorter-margin       : 0;
$toolbar__sorter-order\@medium: 1;

@import 'toolbar';
@import 'nobleplace-toolbar';
