$range-background: #ccc;
$range-handle-height: 20px;
$range-handle-width: 20px;
$range-handle-lower-right: -20px;
$range-target-height: 3px;
$range-input-border: 1px solid #c8c8c8;
$range-input-padding: 8px 0 8px 0;
$range-input-height: 32px;
$range-suffix-padding: 0 4px 0 0;

@import 'range';
@import 'nobleplace-range';
