.checkbox {
    transition-property: $transition-property;

    &__field {
        &:disabled,
        &[disabled] {
            cursor: not-allowed;

            & ~ .checkbox {
                &__label {
                    color: $gray-dark;
                }

                &__icon {
                    opacity: 0.5;
                }
            }
        }
    }
}
