$success-page-product-item__image-width           : 88px;
$success-page-product-item__image-width\@medium   : 144px;
$success-page-product-item__image-width\@large    : 240px;
$success-page-product-item-brand__font-size\@large: 20px;
$success-page-product-item-name__font-size\@large : 22px;
$success-page-product-item-price__font-weight     : 600;
$success-page-product-item-price__font-size\@large: 22px;

.success-additional-info {
    &__order {
        padding: $spacer--semi-medium 0;
        text-align: left;
        border-bottom: $border-base;

        @include mq($screen-l) {
            font-size: $font-size-large;
        }
    }

    &__value {
        font-weight: $font-weight-bold;
    }

    &__product {
        padding: $spacer--semi-medium 0;
        border-bottom: $border-base;
        display: flex;
        align-items: center;
    }

    &__product-img {
        margin-right: $spacer--medium;
        min-width: $success-page-product-item__image-width;

        @include mq($screen-m) {
            width: $success-page-product-item__image-width\@medium;
            min-width: $success-page-product-item__image-width\@medium;
        }

        @include mq($screen-l) {
            width: $success-page-product-item__image-width\@large;
            min-width: $success-page-product-item__image-width\@large;
        }
    }

    &__product-info {
        font-size: $font-size-small;
        width: 100%;
        text-align: left;
        padding: $spacer 0;
    }

    &__product-brand {
        display: block;
        font-size: $font-size-small;
        margin-bottom: $spacer--extra-small;

        @include mq($screen-l) {
            font-size: $success-page-product-item-brand__font-size\@large;
        }
    }

    &__product-name {
        display: block;
        color: $gray-dark;
        font-size: $font-size-small;
        margin-bottom: $spacer--extra-small;

        @include mq($screen-l) {
            font-size: $success-page-product-item-name__font-size\@large;
        }
    }

    &__product-sku {
        display: block;
        font-size: $font-size-extra-small;
        margin-bottom: $spacer--extra-small;

        @include mq($screen-l) {
            font-size: $font-size-medium;
        }
    }

    &__product-price {
        font-size: $font-size-base;
        font-weight: $success-page-product-item-price__font-weight;

        @include mq($screen-l) {
            font-size: $success-page-product-item-price__font-size\@large;
        }
    }

    &__summary {
        padding-bottom: $spacer--small;
        border-bottom: $border-base;
    }

    &__section-title {
        font-weight: $font-weight-bold;
        font-size: $font-size-medium;
        text-align: left;
        padding: $spacer--small 0;

        @include mq($screen-l) {
            font-size: $font-size-super-extra-large;
        }
    }

    &__summary-row-total,
    &__summary-row {
        display: flex;
        justify-content: space-between;
        padding: $spacer 0;

        @include mq($screen-l) {
            font-size: $font-size-large;
        }
    }

    &__summary-row-total {
        font-weight: $font-weight-bold;
    }

    &__shipping {
        text-align: left;
        padding-bottom: $spacer--semi-medium;

        @include mq($screen-l) {
            font-size: $font-size-large;
        }
    }

    &__shipping-address {
        padding-bottom: $spacer--medium;
    }
}
