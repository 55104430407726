.article-item {
    &--single {
        .article-item__title {
            line-height: $font-line-height;
            text-align: center;
        }
    }

    &__banner {
        display: flex;
        justify-content: center;
    }
}
