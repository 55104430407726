.radio {
    &__label {
        margin: 0;
        padding: $radio__label-margin;

        &:before {
            top: 3px;
        }

        &:after {
            top: 18px;
            left: 3px;
        }
    }
}
