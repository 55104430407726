$success-page__padding        : 60px 0 $spacer--extra-large;
$success-page__button-margin  : $spacer--large auto;
$success-page__button-width   : 100%;
$success-page__button-width\@s: auto;

.success-page {
    text-align: center;

    &__main {
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($screen-l) {
            padding-top: $spacer--semi-medium;
            background-color: $white;

            svg {
                width: $spacer--extra-large;
                height: $spacer--extra-large;
            }
        }
    }

    &__title {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        margin: $spacer 0;
        text-transform: none;

        @include mq($screen-l) {
            font-size: $font-size-super-extra-large;
        }
    }

    &__paragraph {
        font-size: $font-size-small;

        @include mq($screen-l) {
            font-size: $font-size-extra-large;
        }
    }

    &__content {
        background-color: $white;
        padding: 0 $spacer--medium;

        @include mq($screen-l) {
            padding: $spacer--medium $spacer--semi-large;
        }
    }

    &__order-confirmation {
        padding: $spacer--large 0;
    }

    &__button {
        width: $success-page__button-width;
        margin: $success-page__button-margin;

        @include mq($screen-s) {
            width: $success-page__button-width\@s;
        }
    }

    &__registration-title {
        font-weight: $font-weight-bold;

        @include mq($screen-l) {
            font-size: $font-size-super-extra-large;
        }
    }

    &__registration-paragraph {
        font-size: $font-size-small;

        @include mq($screen-l) {
            font-size: $font-size-large;
        }
    }
}
