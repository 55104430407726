.filters {
    &__modal,
    &__container,
    &__dropdown,
    .dropdown-list__label {
        @include mq($screen-l) {
            background-color: transparent;
        }
    }

    &__heading {
        line-height: 48px;
        @include mq($screen-l) {
            border-bottom: 1px solid $gray-layered;
        }
    }

    &__trigger {
        justify-content: center;
        font-size: $font-size-medium;
    }
}
