.add-to-cart {
    &--hidden {
        visibility: hidden;
    }

    @include mq($max-screen: $screen-m - 1) {
        &__wrapper--reservation {
            flex-direction: column;
            padding-top: $spacer;
        }

        &__action--reservation {
            flex-direction: row;
            margin-top: $spacer;
        }

        &__title--reservation {
            width: 100%;
            text-align: center;
        }

        &__action-secondary--reservation {
            width: unset;
            right: 0;
            bottom: $spacer--extra-large;
        }
    }
}
