.product-grid-item {
    background-color: $white;

    &__name-link {
        text-align: center;
        text-transform: none;
        font-size: $font-size-base;
    }

    &__price {
        width: 100%;
        text-align: center;

        .price__value.price__value--hidden {
            display: none;
        }
    }

    &__manufacturer {
        text-align: center;
        margin-bottom: $spacer;
        font-size: $font-size-small;
        color: $gray-dark;
    }

    &__name {
        margin-bottom: 0;
    }

    &__sku {
        display: block;
        font-size: $font-size-small;
        color: $gray-dark;
        text-align: center;
        margin-bottom: $spacer;
    }

    .price__value {
        font-size: $font-size-small;

        @include mq($screen-m) {
            font-size: $font-size-base;
        }
    }

    &--slider-item {
        .product-grid-item {
            &__image-wrapper {
                @include mq($max-screen: $screen-m - 1) {
                    margin-top: $spacer--semi-medium;
                }
            }
        }
    }
}
