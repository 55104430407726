.video {
    position: relative;
    overflow: hidden;
    height: auto;
    padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);

    @include mq($screen-m) {
        padding-bottom: calc(var(--aspect-ratio--m, 0.5625) * 100%);
    }

    &__thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;

        img.ratio-image {
            max-width: fit-content;
            height: 100%;
            object-fit: cover;

            @include mq($screen-m) {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    &__overlay {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    &__play-btn {
        width: 64px;
        height: 64px;
        min-width: 64px;
        border-radius: 50%;
        z-index: 2;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
