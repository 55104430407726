.showrooms {
    &__intro {
        .teaser--column {
            .teaser__heading,
            .teaser__content,
            .teaser__content-block {
                background-color: transparent;
            }
        }
    }

    &__list-item {
        padding: $spacer--xl 0 $spacer--xxl;
    }
}
