.request-product-details {
    &__title {
        text-align: center;
        margin-bottom: $spacer--semi-medium;

        @include mq($screen-s) {
            margin-bottom: $spacer;
        }

        @include mq($screen-m) {
            margin-bottom: $spacer--extra-large;
        }
    }

    &__product {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-bottom: $spacer--extra-large;
        padding-bottom: $spacer--semi-medium;
        border-bottom: $border-base;

        @include mq($screen-m) {
            border-bottom: none;
            flex-direction: column;
        }
    }

    &__product-image {
        width: 50%;
        max-width: 208px;

        @include mq($screen-m) {
            padding-bottom: $spacer;
            width: auto;
            max-width: 100%;
        }
    }

    &__product-details {
        width: 50%;
        text-align: left;
        padding: 0 $spacer--medium;

        @include mq($screen-m) {
            width: auto;
            text-align: center;
        }
    }

    &__product-brand {
        margin-bottom: $spacer--medium;
        display: block;
        color: $gray-darkest;
        font-size: $font-size-small;
    }

    &__product-name {
        display: block;
        color: $gray-dark;
    }

    .fieldset {
        > .legend {
            @include visually-hidden();
        }
    }

    .field {
        margin-bottom: $spacer--large;

        &:last-child {
            margin-bottom: 0;
        }

        &.type-html {
            padding: $spacer--medium 0 0 $spacer--large;
            font-size: $font-size-small;
            color: $gray-dark;
            margin-bottom: $spacer--medium;
        }

        .input__field--textarea {
            resize: vertical;
        }
    }

    .checkbox__label {
        color: $gray-dark;
        font-size: $font-size-small;
    }
}
