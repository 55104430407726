.table {
    &--scope-row {
        tbody {
            display: grid;
            grid-template-columns: 1fr;

            @include mq($screen-l) {
                grid-template-columns: repeat(2, 1fr);
                column-gap: 96px;
            }
        }

        tr {
            display: flex;
            justify-content: space-between;
        }

        td {
            text-align: right;
        }

        th {
            color: $gray-dark;

            &[scope="row"] {
                & + td {
                    flex-flow: row nowrap;
                    width: 100%;
                    text-align: right;
                    justify-content: space-between;

                    &:before {
                        text-align: left;
                        color: $gray-dark;
                        font-weight: $font-weight-normal;
                    }
                }
            }
        }
    }

    &--clean {
        background-color: transparent;

        tbody {
            tr:last-child {
                border: none;
            }
        }

        tr,
        td,
        th {
            border: none;
            background-color: transparent;
        }

        td {
            width: 100%;
        }
    }
}
