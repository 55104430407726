$cart-cross-heading-font-size: 36px;
$cart-cross-sell-padding     : 100px;
$cart-cross-sell-background  : #F3F3F3;

@import 'main';

.cart {
    .related,
    .crosssell {
        padding: 0 $spacer--extra-small;

        @include mq($screen-m) {
            padding: 0 $spacer--large;
        }

        @include mq($screen-l) {
            padding: 0 $spacer--semi-large;
        }
    }

    &__summary {
        margin: $spacer--medium 0;
    }
}

.checkout-cart-index {
    .crosssell {
        margin: 0 -16px;
        padding: 0 $spacer--large;
        background-color: $cart-cross-sell-background;

        @include mq($screen-l) {
            padding: 0 $cart-cross-sell-padding;
            margin: 0;
        }
            
        .section__heading {
            justify-content: flex-start;
            margin-top: unset;
            padding: $spacer--medium 0 $spacer--extra-small;
            margin-bottom: 0;
            position: unset;
    
            &:after {
                display: none;
            }

            .heading--first-level {
                font-size: $font-size-medium;
                text-transform: none;
                letter-spacing: unset;
                font-weight: $font-weight-medium;

                @include mq($screen-l) {
                    font-size: $cart-cross-heading-font-size;
                    margin-bottom: $spacer--medium;
                }
            }
        }
    }

    .additional-contact-info {
        background-color: $white;
        padding: $spacer--medium;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;

        @include mq($screen-m) {
            &__container {
                max-width: $max-content-width;
                margin: auto;
                padding: $spacer--large $cart-cross-sell-padding;
            }
        }

        @include mq($screen-m) { 
            &__paragraphs {
                border: 1px solid $gray-dark;
                padding: $spacer--semi-medium $spacer--semi-medium $spacer--extra-large;
            }
        }

        &__paragraph {
            margin: 0;
            font-size: $font-size-small;

            @include mq($screen-m) { 
                font-size: $font-size-large;
                margin-bottom: $spacer;
            }
        }
        
    }
}
