$amgdprcookie-table-container__min-width: 1000px;

.gdpr-customer-settings {
    .amgdprcookie-table-container {
        overflow-x: auto;
    }

    .amgdprcookie-table {
        min-width: $amgdprcookie-table-container__min-width;
    }

    .amgdprcookie-title {
        white-space: nowrap;
        padding: $spacer--extra-small $spacer;
    }

    .amgdprcookie-gdrp-buttons {
        display: flex;
    }

    .amgdprcookie-gdrp-privacy-link {
        color: $white;
    }

    .amgdprcookie-cookie-container {
        padding: $spacer--semi-medium 0;
    }
}
