$cart-list-item__brand-font-size            : $font-size-small;
$cart-list-item__brand-font-size\@medium    : 20px;
$cart-list-item__brand-margin-bottom        : $spacer--extra-small;
$cart-list-item__brand-margin-bottom\@medium: $spacer--semi-medium;
$cart-list-item__name-margin-bottom         : $spacer--extra-small;
$cart-list-item__price-font-weight          : 600;
$cart-list-item__wrapper-margin-bottom      : 14px;
$cart-list-item__action-text-font-size      : $font-size-extra-small;
$cart-list-item__action-icon-size           : 18px;
$cart-list-item__action-icon-margin-right   : $spacer--extra-small;
$cart-list-item__name-font-size\@medium     : 22px;
$cart-list-item__input-width                : 40px;
$cart-list-item__input-width\@small         : 90px;

.cart-list-item {
    display: flex;

    @include mq($screen-m) {
        flex-wrap: nowrap;
    }

    &:last-child {
        border-bottom: 0;
    }

    &__left {
        @include mq($screen-m) {
            flex-basis: unset;
        }

        @include mq($screen-xl) {
            flex-basis: unset;
        }
    }

    &__right {
        @include mq($screen-m) {
            flex-basis: unset;
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 300px;
        }
    }

    &__image {
        margin-right: $spacer--medium;
        min-width: $cart-list-item__image-width;

        @include mq($screen-m) {
            width: $cart-list-item__image-width\@medium;
            min-width: $cart-list-item__image-width\@medium;
        }

        @include mq($screen-l) {
            width: $cart-list-item__image-width\@large;
            min-width: $cart-list-item__image-width\@large;
        }
    }

    &__action {
        background-color: transparent;

        &:hover,
        &.focus-visible {
            background-color: transparent;
        }
    }

    @include mq($screen-m) {
        &__details {
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            height: 100%;
            grid-column: 2 / 3;
            grid-row: 2 / 4;
        }
    }

    &__gift-dropdown {
        &.dropdown-list {
            background-color: transparent;
        }
    }

    &__brand {
        font-size: $cart-list-item__brand-font-size;
        margin-bottom: $cart-list-item__brand-margin-bottom;

        @include mq($screen-m) {
            font-size: $cart-list-item__brand-font-size\@medium;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
    }

    &__name {
        margin-bottom: 0;
        margin-bottom: $cart-list-item__name-margin-bottom;

        @include mq($screen-m) {
            font-size: $cart-list-item__name-font-size\@medium;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }
    }

    &__wrapper {
        margin-bottom: $cart-list-item__wrapper-margin-bottom;
    }

    &__price {
        font-weight: $cart-list-item__price-font-weight;
    }

    &__label {
        display: none;
    }

    &__input {
        width: $cart-list-item__input-width;
        border-width: 1px 0 1px 1px;

        @include mq($screen-s) {
            width: $cart-list-item__input-width\@small;
        }
    }

    @include mq($screen-m) {
        &__qty {
            padding: 0;
        }
    }

    &__update {
        border-color: $black;
        padding: 0 $spacer;

        @include mq($screen-s) {
            padding: 0 $spacer--large;
        }
    }

    &__error {
        width: 100%;

        @include mq($screen-m) {
            padding-left: $spacer--large;
            grid-column: 2 / 3;
            grid-row: 4 / 5;
        }
    }

    &__actions {
        flex-direction: row-reverse;
        justify-content: flex-end;

        @include mq($screen-m) {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
        }

        .button__icon {
            width: $cart-list-item__action-icon-size;
            height: $cart-list-item__action-icon-size;
            margin-right: $cart-list-item__action-icon-margin-right;
            fill: $gray-dark;
        }
    }

    &__remove {
        margin-right: 0;
    }

    &__action-text {
        color: $gray-dark;
        font-size: $cart-list-item__action-text-font-size;
        text-transform: none;
    }

    @include mq($screen-m) {
        .gift-options-cart-item {
            display: none;
        }
    }
}
