$table__background-odd--clean       : transparent;
$table__background-even--clean      : transparent;
$table__th-background-row           : transparent;
$table__tr-background-odd--scope-row: transparent;
$table__font-size\@medium           : $font-size-base;
$table__td-border-bottom            : none;
$table__td-border-bottom\@medium    : none;

@import 'table';
@import 'nobleplace-table';
