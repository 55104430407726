$filters__heading-font-size\@medium: $font-size-large;
$filters__heading-font-weight      : $font-weight-medium;
$filters__heading-padding\@large   : 0 $spacer $spacer--semi-medium 0;

$filters__trigger-padding          : $spacer--large 0;
$filters__trigger-padding\@medium  : $spacer--large 0;
$filters__trigger-border-width     : 1px 0 1px 0;

@import 'filters';
@import 'nobleplace-filters';
