$product-view__form-border-width                 : 0;
$product-view__add-to-cart-height                : inherit;
$product-view__add-to-cart                       : inline-flex;
$product-view__button-wishlist-background-color  : transparent;
$product-view__button-wishlist-width             : inherit;
$product-view__price-display                     : block;
$product-view__wrapper-grid-template-rows\@medium: auto 1fr auto;

$product-view__tabs-title-padding                : 0 $spacer--extra-large 0 $spacer--extra-large;
$product-view__tabs-title-text-align             : center;

@import 'product-view';
@import 'nobleplace-product-view';
