$order-summary__padding               : 0 20px 20px;
$order-summary__padding\@medium       : 60px 100px 120px;
$order-summary__background            : $white;
$order-summary__margin                : 0 -8px 0;
$order-summary__title-font-size\@large: 36px;
$order-summary__title-margin          : 0;
$order-summary__list-border           : 0;

@import 'order-summary';
@import 'nobleplace-order-summary';
