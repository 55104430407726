html[lang="lv"] {
    .product-credit__button {
        display: none;
    }

    .product-video-presentation__button {
        display: none;
    }
}

html[lang="pl"] {
    .lbx-wrapper {
        opacity: 0;

        &:has(> #lb-search-element) {
            opacity: 1;
        }
    }
}