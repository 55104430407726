$add-to-cart-modal-mobile-max-width : 135px;
$add-to-cart-modal-desktop-max-width: 240px;
$add-to-cart-modal-desktop-spacer   : 115px;
$add-to-cart-related-background     : #F3F3F3;
$add-to-cart-desktop-name-font-size : 22px;
$add-to-cart-desktop-price-font-size: 22px;
$add-to-cart-button-max-width       : 400px;

.add-to-cart-modal {
    height: unset;
    min-height: 100vh;
    overflow: auto;

    &__container {
        padding: $spacer $spacer--medium;

        @include mq($screen-l) {
            &.modal__container {
                width: 75%;
                height: unset;
                max-height: calc(100vh - 64px);
                overflow: auto;
                max-width: $max-content-width;
                margin: $spacer--medium auto 0;
                padding: $spacer--large $spacer--extra-large 0;
            }
        }

        @include mq($screen-xxl) {
            padding: $spacer--extra-large $add-to-cart-modal-desktop-spacer 0;
        }
    }

    &__title {
        font-size: $font-size-medium;
        padding: 0 $spacer--extra-large;
        font-weight: $font-weight-bold;
        text-align: center;
        text-transform: unset;

        @include mq($screen-l) {
            text-align: left;
            font-size: $font-size-extra-large;
            padding: 0;
        }
    }

    &__product {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($screen-l) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__product-content {
        width: 100%;
    }

    &__product-img {
        max-width: $add-to-cart-modal-mobile-max-width;
        margin-bottom: $spacer--medium;
        width: 100%;

        @include mq($screen-l) {
            max-width: $add-to-cart-modal-desktop-max-width;
            margin-right: $spacer--semi-medium;
            margin-bottom: $spacer--semi-medium;
        }
    }

    @include mq($screen-l) {
        &__product-details {
            display: flex;
            width: 100%;
            margin-bottom: $spacer--semi-medium;
        }
    }

    &__product-info {
        display: flex;
        flex-direction: column;
        padding: 0 $spacer--large;

        @include mq($screen-l) {
            width: 100%;
            padding: 0;
            margin-right: $spacer--semi-large;
        }
    }

    &__product-brand,
    &__product-name {
        display: block;
        margin-bottom: $spacer--medium;
        text-align: center;

        @include mq($screen-l) {
            text-align: left;
        }
    }

    &__product-brand {
        font-size: $font-size-small;

        @include mq($screen-l) {
            font-size: $font-size-medium;
        }
    }

    &__product-name {
        color: $gray-dark;
        font-weight: $font-weight-medium;

        @include mq($screen-l) {
            font-size: $add-to-cart-desktop-name-font-size;
        }
    }

    &__price {
        display: flex;
        justify-content: center;
        margin-bottom: $spacer--semi-medium;

        @include mq($screen-l) {
            align-items: flex-end;
            margin-bottom: $spacer--medium;

            .price__value {
                font-size: $add-to-cart-desktop-price-font-size;
            }
        }
    }

    &__links {
        margin-bottom: $spacer--semi-medium;
        display: flex;
        flex-direction: column;
        align-items: center;

        .button {
            width: 100%;
            max-width: $add-to-cart-button-max-width;

            &:hover {
                color: $white;
            }
        }

        .action {
            margin-bottom: $spacer--small;
        }

        @include mq($screen-l) {
            flex-direction: row-reverse;
            margin-bottom: $spacer--semi-large;
            align-items: unset;

            .button {
                max-width: unset;
            }

            .button.action {
                margin-bottom: 0;
                margin-left: $spacer--semi-large;
            }
        }
    }

    &__related {
        margin: 0 -16px;
        padding: 0 $spacer--medium;
        background-color: $add-to-cart-related-background;

        @include mq($screen-l) {
            margin-left: -$spacer--extra-large;
            margin-right: -$spacer--extra-large;
            padding: 0 $spacer--extra-large 0;
        }

        @include mq($screen-xxl) {
            padding: 0 $add-to-cart-modal-desktop-spacer 0;
            margin-left: -$add-to-cart-modal-desktop-spacer;
            margin-right: -$add-to-cart-modal-desktop-spacer;
        }

        .section__heading {
            justify-content: flex-start;
            margin-top: unset;
            padding: $spacer--medium 0 $spacer--extra-small;
            margin-bottom: 0;
            position: unset;

            &:after {
                display: none;
            }

            .heading--first-level {
                font-size: $font-size-medium;
                text-transform: none;
                letter-spacing: unset;
                font-weight: $font-weight-medium;
            }
        }
    }

    &__close {
        &.button.modal__close-button {
            top: $spacer;
            width: unset;
        }
    }
}

.add-to-cart-modal-trigger {
    display: none;
}
