.webforms {
    .form {
        .webforms-datepicker {
            margin-bottom: $spacer--medium;
        }
        .actions-toolbar,
        .fieldset,
        .field {
            padding: 0;
            float: none;
        }
    }

    .webforms-datepicker {
        min-height: 330px;
    }

    .webforms-field {
        float: none;
    }

    &__modal-container {
        overflow: auto;
        .modal__close-button {
            width: auto;
        }
    }

    &__modal-content {
        padding-top: $spacer--semi-medium;
    }
}
