.catalog-grid {
    grid-template-columns: 1fr 1fr;

    @include mq($screen-m) {
        grid-gap: $spacer;
    }

    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
