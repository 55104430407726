.instagram-feed {
    margin-top: 270px;
    padding-top: 32px;
    padding-bottom: 40px;

    @include mq($screen-xl) {
        padding-bottom: 80px;
    }

    &__wrapper {
        position: relative;
        display: flex;
        height: 144px;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        @include mq($screen-s) {
            height: 224px;
        }

        @include mq($screen-xl) {
            height: 320px;
        }
    }

    &__content {
        flex-wrap: nowrap;
        max-width: 100vw;
        grid-gap: $spacer;

        @include mq($screen-s) {
            grid-gap: $spacer--medium;
        }

        .mpinstagramfeed-photo {
            display: none;

            &:nth-child(-n+2) {
                display: block;
            }

            @include mq($screen-m) {
                &:nth-child(-n+3) {
                    display: block;
                }
            }

            @include mq($screen-l) {
                &:nth-child(-n+4) {
                    display: block;
                }
            }

            @include mq($screen-xl) {
                &:nth-child(-n+5) {
                    display: block;
                }
            }

            @include mq($screen-xxl) {
                &:nth-child(-n+3) {
                    display: block;
                }
            }
        }
    }
}
