.banner {
    &--secondary {
        padding-top: 56px;
        background-color: $white;
        text-align: center;

        .banner__text {
            width: 100%;
            text-align: center;
        }

        .banner__content {
            position: relative;
            margin: 0 1px $spacer--semi-large;

            &:after {
                width: $banner__content-after-width-hover;
                transform: translateX(-50%);
            }
        }

        img {
            margin: auto;
            padding: 1px;
        }

        &:hover,
        &.focus-visible {
            .banner__content {
                &:after {
                    background-color: $color-primary;
                }
            }

            .ratio-container:after {
                content: none;
            }
        }
    }

    &--brand {
        position: relative;
        overflow: hidden;

        .banner__content {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;

            .lazyload-wrapper {
                background-color: transparent;
            }
        }
    }
}
