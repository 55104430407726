@import 'dropdown-list';

.dropdown-list {
    &--flags {
        width: auto;
        margin-left: $spacer;

        .dropdown-list__flag {
            height: 18px;
        }

        .dropdown-list__label {
            padding-top: 0;
            padding-bottom: 0;
            z-index: $z-index-normal + 1;
        }

        .dropdown-list__content {
            position: absolute;
            top: 0;
            z-index: $z-index-normal;
        }

        .list__item {
            display: flex;
            padding: 0;
            margin-bottom: $spacer--small;
        }

        .switcher-options {
            display: flex;
            justify-content: flex-end;

            .side-menu__modal & {
                margin-right: 4px;
            }
        }
    }

    &__label {
        .dropdown-list__icon {
            width: $spacer--bigger-medium;
            height: $spacer--bigger-medium;
        }

        .dropdown-list__icon:after {
            content: '';
            display: block;
            height: $spacer--bigger-medium;
            width: $spacer--bigger-medium;
            background: transparent none no-repeat 50%;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjEuMyIgZD0iTTAgNS4zNWgxMU01LjM1IDExVjAiLz48L3N2Zz4=");
        }
    }

    &__label[aria-expanded="true"] {
        .dropdown-list__icon:after {
            content: '';
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS4zIiBkPSJNMCAxLjM1aDExIi8+PC9zdmc+");
        }
    }

    &__content {
        overflow: unset;
    }

    &__actions {
        text-align: center;
        margin-top: $spacer--bigger-medium;

        a {
            text-decoration: underline;
        }
    }

    .layered-checkbox {
        display: flex;
        align-items: center;

        &__input {
            display: none;
            height: 1px;
            opacity: 0;
            width: 1px;
        }

        &__check {
            z-index: 2;
            background-position: 50%;
            background-repeat: no-repeat;
            border-radius: 2px;
            display: flex;
            height: $spacer--medium;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 5px;
            transition: all 0.2s ease;
            width: $spacer--medium;
        }

        &__element {
            background-position: 50%;
            background-repeat: no-repeat;
            border-radius: 2px;
            display: flex;
            height: $spacer--medium;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 5px;
            transition: all 0.2s ease;
            width: $spacer--medium;
            background-color: $white;
            border: 1px solid #9e9e9e;
            box-shadow: 0 0 0 0 transparent;
            transform: scale(1);
            z-index: 1;
        }

        &__label {
            display: inline-block;
            font-size: 14px;
            padding: 3px 0 0 25px;
            color: #050505;
        }

        &:hover {
            .layered-checkbox__input,
            .layered-checkbox__check,
            .layered-checkbox__element {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                transform: scale(1.3);
            }

            .layered-checkbox__element {
                border: 1px solid $black;
            }
        }

        &.checkbox--link-checked {
            .layered-checkbox__element {
                background-color: $black;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSI4Ij48cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjM3OCA3LjYwNGMtLjA1Mi4wNTItLjEyMi4wNTItLjE3NCAwTC4wNCAzLjQ0Yy0uMDUzLS4wNTItLjA1My0uMTIyIDAtLjE3NGwxLjQ0My0xLjQ0NGMuMDUyLS4wNTIuMTIyLS4wNTIuMTc0IDBMNC4wMyA0LjE5NWMuMDM1LjAzNS4wNy4wMzUuMTA1IDBMOC4yOTEuMDRjLjA1Mi0uMDUyLjEyMS0uMDUyLjE3NCAwbDEuNjUyIDEuNjUyYy4wNTIuMDUyLjA1Mi4xMjEgMCAuMTc0bC01LjczOSA1Ljc0eiIvPjwvc3ZnPg==); // sass-lint:disable-line no-misspelled-properties space-after-colon space-around-operator space-after-comma
            }
        }

    }
}
