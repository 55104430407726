// Colors
// name                     //  hex       //  design name
$gray-darkest               : #050505;  //  primary-gray-100
$gray-dark                  : #66686c;  //  gray-80
$gray                       : #c8cace;  //  gray-60
$gray-light                 : #eceef2;  //  gray-40
$gray-lighter               : #f4f5f8;  //  gray-20
$gray-lightest              : #f9fafb;  //  gray-10
$gray-layered               : #e8e8e8;

$bright-sky-blue            : #00b8ff;
$carmine                    : #960018;  //  red-100
$button-green               : #004532;

$color-primary              : $gray-darkest;
$color-secondary            : $gray-dark;
$color-tertiary             : $carmine;
$color-error                : $color-tertiary;

// Typography
$font-family-sans-serif     : 'Raleway', sans-serif;
$font-family-base           : $font-family-sans-serif;
$form-elements-border-focus : 2px solid $bright-sky-blue;

$font-color-base            : $color-primary;

$letter-spacing--small      : 1.5px;
$letter-spacing--medium     : 2px;
$letter-spacing--large      : 2.67px;
$letter-spacing--extra-large: 3px;

$line-height--medium        : 2;
$line-height--large         : 2.29;
$line-height--extra-large   : 3;

$transition-property        : background-color, border-color;

// spacings from design
$spacer--bigger-medium      : 20px;
$spacer--l                  : 64px;
$spacer--xl                 : 88px;
$spacer--xxl                : 120px;
$spacer--xxl-2              : 240px;
