.range-filter {
    &__info {
        font-size: $font-size-base;
        text-transform: lowercase;
    }

    &__input {
        background: $white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        border: $range-input-border;
        font-size: $font-size-small;
        height: $range-input-height;
        max-width: 80px;

        input::-webkit-inner-spin-button {
            display: none;
        }

        .input__field {
            border: 0;
            padding: $range-input-padding;
            text-align: right;
            font-family: "Raleway";
        }
    }

    &__suffix {
        padding: $range-suffix-padding;
    }

    &__button:hover {
        background-color: $button-green;
    }

    .checkbox__field {
        :hover {
            scale: 1.1;
        }
    }

    .noUi-connect {
        background-color: $range-background;
    }

    .noUi-target {
        height: $range-target-height;
    }

    .noUi-horizontal {
        .noUi-handle {
            width: $range-handle-width;
            height: $range-handle-height;

            &.noUi-handle-lower {
                right: $range-handle-lower-right;
            }
        }
    }
}
