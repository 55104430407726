.brand {
    &__info {
        margin: $spacer--xl 0;
    }

    &__info-content {
        margin: auto;
    }

    &__toggle-button {
        margin-top: $spacer--semi-medium;
    }

    .brand__toggle-button-more {
        display: none;
    }

    &__info-text {
        text-align: center;

        &--hide {
            .brand__toggle-button-more {
                display: block;
            }

            .brand__toggle-button-less {
                display: none;
            }

            & > p {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        p {
            font-size: $font-size-base;
            line-height: $line-height--large;

            @include mq($screen-m) {
                font-size: $font-size-medium;
                line-height: 2;
            }
        }
    }

    &__section-wrapper {
        overflow: auto;
    }

    &__logo-wrapper {
        padding: $spacer--large 0;
        @include mq($screen-l) {
            border-top: 1px solid $gray;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 48px;
    }

    &__filters {
        text-align: center;
    }

    &__categories {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($screen-m) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: $spacer;
        }

        @include mq($screen-l) {
            column-gap: $spacer--large;
        }
    }

    &__category-item {
        max-width: 420px;
        display: flex;
        flex-direction: column;
        margin: 0 0 $spacer 0;
        padding: 0;

        &--full {
            order: -1;
            width: 100%;
        }

        @include mq($screen-s) {
            margin: 0 0 $spacer--semi-medium 0;
        }

        @include mq($screen-m) {
            grid-column-start: auto;
            grid-column-end: auto;
            max-width: none;
            margin: 0;

            &--full {
                grid-column-start: 1;
                grid-column-end: span 3;
                grid-row-start: 1;
                margin-bottom: $spacer;
            }
        }

        @include mq($screen-l) {
            &--full {
                margin-bottom: $spacer--large;
            }
        }
    }

    &__category-name {
        padding: $spacer--semi-medium 0;

        @include mq($screen-l) {
            padding: $spacer--large 0;
        }
    }
}
