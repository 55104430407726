.pager {
    &__link {
        &--next,
        &--prev {
            background-color: transparent;
        }
    }

    &__item {
        position: relative;
        &--current {
            &:after {
                position: absolute;
                content: '';
                bottom: -25px;
                height: 1px;
                width: 100%;
                background-color: $gray-darkest;
                z-index: 1;
            }
        }
    }
}
