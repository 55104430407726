.fotorama__stage[data-fotorama-stage="fotorama__stage"] {
    padding: 1px;
    margin-bottom: $spacer--semi-medium;
    margin-left: 0;

    @include mq($screen-l) {
        width: 100% !important; // sass-lint:disable-line no-important
    }

    .fotorama__img {
        padding: 1px;
    }

    .fotorama-stage__shaft {
        width: 100% !important; // sass-lint:disable-line no-important
    }
}

.fotorama__zoom-in-icon {
    display: none;
}

.fotorama__nav__shaft {
    padding-top: 1px;
}
