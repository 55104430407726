$discount-coupon-width       : 340px;
$discount-padding-horizontal : 108px;
$discount-padding-vertical   : 60px;
$discount-title-margin-bottom: 60px;
$discount-title-font-size    : 36px;

.cart-discount {
    background-color: $white;
    margin-left: -$spacer;
    margin-right: -$spacer;
    padding: 0 $discount-padding;

    @include mq($screen-l) {
        padding: $discount-padding-vertical $discount-padding-horizontal;
    }

    &__title {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        line-height: 42px;

        @include mq($screen-l) {
            display: block;
            font-size: $discount-title-font-size;
            margin-bottom: $discount-title-margin-bottom;
        }
    }

    &__content-field {
        border-width: 1px 0 1px 1px;
    }

    &__content-button {
        border-color: $black;
    }


    @include mq($screen-m) {
        .cart-discount__controls {
            width: $discount-coupon-width;
        }
    }
}
