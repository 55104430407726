.bg-white {
    background-color: $white;
}
.ovh {
    overflow: hidden;
}

.bg-gray {
    background-color: $gray-lighter;
}

.overflow-y-auto {
    overflow-y: auto;
}

.max-height-none {
    max-height: none;
}
