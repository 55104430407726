$go-to-checkout-padding: 25px 20px;

.go-to-checkout {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    padding: $go-to-checkout-padding;
    border-top: 1px solid $gray;

    @include mq($screen-m) {
        display: none;
    }

    &--hidden {
        display: none;
    }

    .button {
        width: 100%;
    }
}
