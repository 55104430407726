.cms-page-view {
    .heading--page {
        margin: $spacer--l 0;
    }

    .cms-page__content {
        padding-bottom: $spacer--l;
        @include mq($screen-l) {
            padding-bottom: $spacer--xxl;
        }
    }
}
