.categories-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $spacer;
    margin: 0;
    padding: $spacer--medium 0 $spacer--large 0;
    background-color: $gray-lighter;

    @include mq($screen-m) {
        grid-gap: $spacer--medium;
        padding-bottom: 152px;
    }

    @include mq($screen-l) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include isIE() {
        display: flex;
        flex-wrap: wrap;
    }
}
