.slider {
    &__navigation {
        max-width: $max-content-width;
        left: 50%;
        transform: translateX(-50%);
    }

    &__prev {
        left: $spacer;

        @include mq($max-content-width) {
            left: $slider__prev-left;
        }
    }

    &__next {
        right: $spacer;

        @include mq($max-content-width) {
            right: $slider__prev-left;
        }
    }

    &__dots {
        position: absolute;
        bottom: 0;
    }

    &--brands {
        background-color: $white;

        .slider__heading {
            margin-top: 88px;
            padding-bottom: $spacer--semi-large;
            text-align: center;

            @include divider-after();
        }

        .slider__container {
            @include mq($screen-s, $screen-m) {
                width: $slider__handler-max-width\@small--brands;
            }

            &:before,
            &:after {
                display: none;
            }
        }

        .slider__handler {
            overflow: visible;
        }


        .slider__item {
            @include mq($screen-s, $screen-m) {
                max-width: $slider__item-width\@small--brands;
            }
        }

        .slick-list {
            overflow: hidden;
        }

        .banner {
            padding: 1px;

            @include divider-after($width: 100%);

            &:after {
                transition: $transition-fade;
                opacity: 0;
            }

            &:hover,
            &:focus-within {
                cursor: pointer;

                &:after {
                    opacity: 1;
                }

                .ratio-container:after {
                    content: none;
                }
            }
        }
    }

    &--brands,
    &--products {
        $nav-button-size--small: 48px;

        .slider__nav-button {
            width: $nav-button-size--small;
            height: $nav-button-size--small;
            min-width: $nav-button-size--small;
            border-radius: $nav-button-size--small / 2;
        }

        .slider__navigation {
            margin-top: -$nav-button-size--small / 2;
        }

        .slider__slides {
            .slick-list {
                min-height: $nav-button-size--small;
            }
        }

        .slider__dots {
            bottom: -$spacer--medium;
            & > li {
                padding: $spacer--extra-small;
            }
        }
    }

    &--outlined-nav {
        .ratio-container {
            &:after {
                display: none;
            }
        }
    }

    .crosssell & {
        .slider__dots {
            bottom: -$spacer--large;
        }
    }
}
