.select {
    &--native {
        select {
            transition-property: $transition-property;
            border: solid $gray-dark;
            border-width: 0 0 1px;
        }
    }

    &__field {
        color: $gray-dark;
        font-family: $font-family-base;
    }
}

.select2-container {
    .select2-selection--single {
        background-color: transparent;
        border-bottom: 1px solid $color-primary;
    }

    .select2-dropdown {
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: $gray;
    }

    &.select2-container--open {
        .select2-selection--single {
            transition-property: $transition-property;
        }
    }
}
