@mixin divider-after($width: 95px) {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: $width;
        height: 1px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-primary;
    }
}
