$slider__nav-button-size                 : 64px;
$slider__nav-button-border-radius        : 50%;
$slider__navigation-display-breakpoint   : $screen-s;

$slider__item-width--brands              : 144px;
$slider__item-to-show--brands            : 2;
$slider__item-width\@small--brands       : 120px;
$slider__item-to-show\@small--brands     : 3;
$slider__handler-max-width\@small--brands: $slider__item-width\@small--brands * $slider__item-to-show\@small--brands;

@import 'slider';
@import 'nobleplace-slider';
