$teaser__content-max-width      : 656px;
$teaser__image-max-width        : 880px;

.teaser {
    &--column {
        flex-direction: column;
        text-align: center;

        .teaser {
            &__image {
                width: 100%;
                max-width: $teaser__image-max-width;
                margin: auto;

                picture {
                    background-color: $white;
                }

                img {
                    margin: auto;
                }
            }

            &__content {
                max-width: $teaser__content-max-width;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: $spacer--l;
                margin: auto;
                background-color: $white;
                line-height: $line-height--large;
            }

            &__heading {
                width: 100%;
                padding: 136px 0;
                max-width: $teaser__content-max-width;
                margin: auto;
                background-color: $white;
                font-weight: $font-weight-medium;
                letter-spacing: $letter-spacing--large;

                @include divider-after();

                &::after {
                    bottom: 87px;
                }
            }
        }
    }
}
