.price-tracker {
    text-align: left;
    font-size: 0.85rem;

    .price-wrapper {
        &:after {
            display: block;
            content: ' ' attr(data-label);
            font-size: 0.85rem;
        }

        .price {
            font-size: 1rem;
            font-weight: $font-weight-bold;
            padding-left: 4px;
        }
    }
}
