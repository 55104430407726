.toolbar {
    &__amount {
        order: 0;
        justify-content: flex-start;
    }

    &__limiter {
        width: 100%;
        order: 2;
        padding-left: $spacer--medium;

        @include mq($screen-s) {
            width: auto;
            padding-left: 0;
        }
    }

    &__mode {
        display: none;
    }

    &__sorter {
        width: 100%;

        @include mq($screen-s) {
            width: 200px;
        }

        @include mq($screen-m) {
            margin-right: $spacer--extra-large;
        }
    }

    &__sorter-link {
        display: none;
    }

    &__sorter-label {
        @include visually-hidden();
    }

    .select2-container .select2-selection--single {
        border-bottom-width: 0;
    }

    &__pager {
        border-bottom: $border-base;
        padding-bottom: $spacer--semi-medium;
    }
}
