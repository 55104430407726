$footer__background-color                                  : $white;
$footer__border                                            : none;
$footer__link-color                                        : $gray-dark;

$footer__links-dropdown-background                         : $white;
$footer__links-label-background                            : $white;
$footer__links-dropdown-list-border                        : none;
$footer__links-dropdown-list-item-border                   : none;
$footer__links-dropdown-list-item-width-first-child\@medium: 100%;
$footer__links-list-item-min-width\@medium                 : 0;

$footer__bottom-bar-background                             : $white;
$footer__copywrite-padding                                 : $spacer--medium;
$footer__copywrite-color                                   : $gray-dark;
$footer__copywrite-font-size                               : $font-size-base;
