.price {
    &__value {
        &--old {
            margin-right: 0;
        }

        &--hidden {
            display: none;
        }
    }
}
