.showroom-item {
    &__content {
        display: flex;
        flex-direction: column;

        @include mq($screen-l) {
            flex-direction: row;
        }
    }

    &__details-wrapper {
        display: flex;
        flex-direction: column;

        @include mq($screen-xl) {
            flex-direction: row;
        }
    }

    &__image-wrapper,
    &__info,
    &__building,
    &__details {
        flex: 0 0 50%;
    }

    &__image-wrapper {
        @include mq($screen-m) {
            padding: 0 $spacer--xl;
        }

        @include mq($screen-l) {
            padding: 0 $spacer--xxl 0 0;
        }
    }

    &__details {
        display: grid;
        grid-gap: $spacer--medium;
        line-height: $line-height--large;

        @include mq($screen-m) {
            font-size: $font-size-medium;
            line-height: $line-height--medium;
        }

        @include mq($screen-l) {
            grid-template-columns: 1fr 1fr;
        }

        @include mq($screen-xl) {
            grid-template-columns: none;
        }
    }

    &__email {
        margin-bottom: $spacer--semi-medium;
    }

    &__info {
        padding: $spacer--extra-large 0;

        @include mq($screen-m) {
            padding: $spacer--extra-large $spacer--xxl;
        }

        @include mq($screen-l) {
            padding: $spacer--extra-large 0;
        }
    }

    &__contact {
        @include mq($screen-l) {
            padding-top: 4px;
        }

        @include mq($screen-xl) {
            padding-top: 0;
        }
    }

    &__map-wrapper {
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 180px;
        margin-top: $spacer--semi-medium;

        @include mq($screen-m) {
            height: 350px;
        }
    }

    &__map {
        width: 100%;
        height: 180px;

        @include mq($screen-m) {
            height: 350px;
        }
    }

    &__building {
        font-weight: $font-weight-medium;
    }

    &--reverse {
        .showroom-item__content {
            @include mq($screen-l) {
                flex-direction: row-reverse;
            }
        }

        .showroom-item__image-wrapper {
            @include mq($screen-m) {
                padding: 0 0 0 $spacer--xl;
            }

            @include mq($screen-l) {
                padding: 0 0 0 $spacer--xxl;
            }
        }
    }
}
