$order-summary-free-shipping-background: #F3F3F3;
$order-summary-button-font-size        : 20px;
$order-summary-shipping-info-font-size : 20px;
$order-summary-shipping-info-padding   : 20px;

.order-summary {
    @include mq($screen-l) {
        padding: $order-summary__padding\@medium;
    }

    &__title {
        line-height: 42px;

        @include mq($screen-l) {
            font-size: $order-summary__title-font-size\@large;
        }
    }

    &__divider {
        display: none;
    }

    &__free-shipping {
        display: flex;
        align-items: center;
        background-color: $order-summary-free-shipping-background;
        padding: $spacer;

        @include mq($screen-m) {
            padding: $order-summary-shipping-info-padding;
        }
    }

    &__security-info {
        padding:  $spacer;
        display: flex;
        align-items: center;
        font-size: $font-size-small;
        background-color: $order-summary-free-shipping-background;

        @include mq($screen-m) {
            font-size: $order-summary-shipping-info-font-size;
            padding: $order-summary-shipping-info-padding;
        }
    }

    &__icon {
        display: flex;
        height: 24px;
        margin-right: $spacer--extra-small;

        @include mq($screen-m) {
            margin-right: $spacer--semi-large;
        }
    }

    &__shipping-info {
        p {
            margin: 0;
            color: $gray-darkest;
            font-size: $font-size-small;

            @include mq($screen-m) {
                font-size: $order-summary-shipping-info-font-size;
            }
        }
    }

    @include mq($screen-m) {
        .button {
            font-size: $order-summary-button-font-size;
            padding: $spacer--medium $spacer--large;
            margin-bottom: $spacer--semi-large;
        }
    }
}
