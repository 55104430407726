// TODO: check for missing custom styles and remove unused styles if needed

// Variables
@import '../Snowdog_Components/components/Atoms/variables/nobleplace-variables';
@import '../Snowdog_Components/components/Atoms/variables/variables';

// Components
@import '../Snowdog_Components/components/styles/non-critical';

// Theme partials
@import '../Amasty_Storelocator/styles/module';
@import '../Amasty_GiftCard/styles/module';
@import '../Magento_Ui/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_Customer/styles/module';
// @import '../VladimirPopov_WebForms/styles/module';
@import '../Magento_PageBuilder/styles/module';
@import '../Magento_QuickOrder/styles/module';
@import '../Yotpo_Yotpo/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../PayPal_Braintree/styles/module';
@import '../Magento_ReCaptchaFrontendUi/styles/module';
// child theme styles
@import '../ForMage_PriceTracker/styles/module';
@import '../Mageplaza_InstagramFeed/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../MageMe_WebForms/styles/module';
@import '../Magento_Cms/styles/global';
@import '../Magento_Theme/styles/module-non-critical';

// Show sections hidden in critical.scss
.page-main,
.footer {
    display: block !important; // sass-lint:disable-line no-important
}
